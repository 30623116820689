div.image {
    content:url("assets/custom/bts/MystryBlog1.jpeg");
    background-size:cover;
}
div.image2 {
    content:url("assets/custom/BlogImages/JB_2.jpg");
    background-size:cover;
}
div.image3 {
    content:url("assets/custom/BlogImages/Blog2.jpg");
    background-size:cover;
}
div.image4 {
    content:url("assets/custom/bts/University_Blue.jpeg");
    background-size:cover;
}
div.image5 {
    content:url("assets/custom/bts/TheNikeSBDunks.jpeg");
    background-size:cover;
}
.blogimage{
    aspect-ratio: auto;
    width: 500px;
    height: auto;
    align-items: center;
}

.secondfont {
    font-size: clamp(1.5rem, 2vw, 2.5rem);
}
