.csContainer {
  height: 100vh;
}
.sideimg {
  height: 100vh;
}
.body {
  background-color: black;
}
.comingsoonH1 {
  font-size: 5vw;
}
.comingsoonH3 {
  font-size: 1.5vw;
}
.csContainer {
  display: flex;
  flex-direction: row;
  margin-left: 10vw;
}
.sidecontent {
  width: 50%;
  padding-top: 20%;
}
.lock {
  height: 14vh;
  width: 14vh;
  padding: 2vh;
}
@media only screen and (max-width: 600px) {
  .yeezy {
    width: 100vw;
    height: 100vh;
  }
  .body {
    height: 100vh;
  }
  .lock {
    position: absolute;
    font-size: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .comingsoonH1 {
    position: absolute;
    font-size: 10vw;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .comingsoonH3 {
    position: absolute;
    font-size: 4vw;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .lock {
    padding: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .body {
    height: 100vh;
  }
  .image {
    width: 100vw;
    padding-right: 10vw;
    padding-left: 10vw;
  }
  .sidecontent {
    width: 0vw;
  }
  .lock {
    position: absolute;
    font-size: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .comingsoonH1 {
    position: absolute;
    font-size: 8vw;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: aliceblue;
  }
  .comingsoonH3 {
    position: absolute;
    font-size: 3vw;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: aliceblue;
  }
  .lock {
    padding: 24px;
  }
}
