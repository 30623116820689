.cardContainerMystry {
  display: flex;
  flex-direction: column;
  background-color: white;
  flex: 0.25;
  border-radius: 18px;
  justify-content: space-around;
  text-align: center;
  padding: 40px 20px 40px 20px;
  flex-basis: 350px;
}

.cardHeader {
  color: black;
  font-size: clamp(1.75rem, 2vw, 5rem);
}

.cardText {
  color: black;
  font-size: clamp(1rem, 1vw, 5rem);
}

.cardImage {
  width: 8vw;
  align-self: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 600px)
{
  .cardImage {
    width: 14vw;
    margin-bottom: 15px;
  }

  .cardContainerMystry {
    margin-bottom: 35px;
    padding: 20px 10px 20px 10px;
  }

  .cardHeader {
    margin-bottom: 12px;
  }
}