.loadingHeading {
  font-family: "edosz";
  font-size: 5rem;
  color: white;
  position: absolute;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.loadingCircle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: loadingCircle 0.5s alternate infinite ease;
}

@keyframes loadingCircle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.loadingCircle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.loadingCircle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.loadingShadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: loadingShadow 0.5s alternate infinite ease;
}

@keyframes loadingShadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.loadingShadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.loadingShadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.loadingWrapper {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loadingSpan {
  position: absolute;
  top: 75px;
  font-size: 20px;
  letter-spacing: 12px;
  color: #fff;
  left: 15%;
}
