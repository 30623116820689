.accordion {
  width: 100%;
  max-width: 1080px;
  height: 400px;
  overflow: hidden;
  margin: 50px auto;
  ul {
    width: 100%;
    display: table;
    table-layout: fixed;
    margin: 0;
    padding: 0;
    li {
      display: table-cell;
      vertical-align: bottom;
      position: relative;
      width: 33.33%;
      height: 400px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      transition: all 500ms ease;
      &:nth-child(1) {
        background-image: url('../../../assets/custom/membership/Black-Render-Square.webp');
      }
      &:nth-child(2) {
        background-image: url('../../../assets/custom/membership/White-Render-Square.webp');
      }
      &:nth-child(3) {
        background-image: url('../../../assets/custom/membership/Gold-Render-Square.webp');
      }
      
      div {
        display: block;
        overflow: hidden;
        width: 100%;
        a {
          display: block;
          height: 400px;
          width: 100%;
          position: relative;
          z-index: 3;
          padding: 15px 20px;
          box-sizing: border-box;
          color: #fff;
          text-decoration: none;
          transition: all 200ms ease;
          * {
            opacity: 0;
            margin: 0;
            width: 100%;
            text-overflow: ellipsis;
            position: relative;
            z-index: 5;
            white-space: nowrap;
            overflow: hidden;
            transform: translateX(-20px);
            transition: all 400ms ease;
          }
          h2 {
            text-overflow: clip;
            font-size: 28px;
            text-transform: uppercase;
            margin-bottom: 2px;
            top: 300px;
          }
          h3 {
            top: 310px;
            font-size: 20px;
          }
        }
      }
    }
    /*ul*/
    &:hover {
      li {
        width: 10%;
        &:hover {
          width: 80%;
          a {
            background: rgba(0, 0, 0, 0.5);
            * {
              opacity: 2;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  body {
    margin: 0;
  }
  .accordion {
    height: auto;
    ul li,
    ul li:hover,
    ul:hover li,
    ul:hover li:hover {
      position: relative;
      display: table;
      table-layout: fixed;
      width: 100%;
      -webkit-transition: none;
      transition: none;
    }
  }
}
