.cart-basket {
  /* font-size: 0.6rem; */
  font-size: 0.75rem;
  position: absolute;
  /* top: 1px; */
  top: -2px;
  right: 3px;
  /* width: 15px;
  height: 15px; */
  width: 18px;
  height: 18px;
  color: #fff;
  background-color: #418deb;
  border-radius: 50%;
  /* border-radius: 100%; */
}

.cart-modal {
  background-color: #2f2f2f;
  font-size: 0.875rem;
}

.cart-modal-position {
  position: absolute;
  /* top: 300px; */
  top: 35%;
  /* transform: translate(-50%, -50%); */
  /* transform: translateY(-2100%) !important; */
  right: 50px;
  max-height: 15px;
}

.cartRemoveButton {
  width: 15px !important;
  /* padding-left: 2px; */
  padding: 1px 1px;
  margin: 2px;
}

.cartImgContainer {
  padding: 2px;
}

@media only screen and (max-width: 450px) {
  .cart-modal-position {
    position: absolute;
    top: 45%;
    /* top: 250px; */
    /* left: 50px; */
    right: 0px;
    width: 95%;
    max-height: 15px;
    z-index: 1000;
  }
}

@media only screen and (max-height: 450px) {
  .cart-modal-position {
    position: absolute;
    top: -5%;
    /* left: 50px; */
    right: 0px;
    width: 95%;
    max-height: 15px;
    z-index: 1000;
  }
}
