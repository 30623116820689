@font-face {
  font-family: "edosz";
  src: local("edosz"), url(../../../assets/fonts/edosz.ttf) format("truetype");
}

@font-face {
  font-family: "helveticaneuebold";
  src: local("helveticaneuebold"),
    url(../../../assets/fonts/helveticaneuebold.ttf) format("truetype");
}

@font-face {
  font-family: "seven_segment";
  src: local("seven_segment"), url(../../../assets/fonts/seven_segment.ttf) format("truetype");
}

.landing-hero{
  padding-top: 60px;
}

.scrollDownContainer {
  /* position: relative; */
  /* width: 24px; */
  /* height: 24px; */
  /* margin: 0px; */
  /* bottom: 125px; */
  cursor: pointer;
  bottom: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  position: absolute;
}

.scrollDownChevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.scrollDownChevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.scrollDownChevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.scrollDownChevron:before,
.scrollDownChevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

.scrollDownChevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.scrollDownChevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.scrollDownText {
  display: block;
  position: inherit;
  top: 75px;
  /* margin-top: 75px; */
  /* margin-left: -30px; */
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.25;
  animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 576px) {
  .priceH3 {
    font-size: 0.875rem;
  }

  .scrollDownContainer {
    cursor: pointer;
    bottom: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    position: absolute;
  }
}

@media only screen and (max-height: 576px) {
  .priceH3 {
    font-size: 1.2rem;
  }

  .scrollDownContainer {
    cursor: pointer;
    bottom: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    position: absolute;
  }
}


.arrow {
  text-align: center;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.Countdown{
  margin: 0px;
}

.Countdown-col{
  display: inline-block;
}

.Countdown-col-element{
  display: inline-block;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element p{
  font-family: "seven_segment";
  margin-bottom: 0px;
}
.Countdown-col-element h4{
  margin-top: 0px;
}

.membership_img{
  display:block;
  position: relative;
  margin:25px auto 0px;
}

.slide-right{
  animation: slide-right 2s infinite, slide-right-pause 8s infinite;
}

.slide-left{
  animation: slide-left 2s infinite, slide-left-pause 8s infinite;
}


@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-right-pause {
  50% {
    right: 0;
  }
  75% {
    right: -120%;
  }
  80% {
    right: 0;
  }
  100% {
    right: 0;
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-left-pause {
  50% {
    left: 0%;
  }
  75%  {
    left: -120%;
  }
  80% {
    left: 0;
  }
  100% {
    left: 0;
  }
}