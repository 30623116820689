.mystryBoxHomeContainer {
  display: flex;
  justify-content: center;
}

.headerMystryHome {
  font-size: clamp(3rem, 1vw, 5rem) !important;
}

.mystryBoxProductSectionHome {
  margin-top: 40px;
  margin-bottom: 20px !important;
  position: relative;
}

.brandsIncluded {
  position: absolute;
  bottom: 0px;
  font-size: clamp(0.57rem, 1vw, 5rem);
}

@media screen and (max-width : 760px){
  .mystryBoxProductSectionHome {
    padding-bottom: 8vh !important;
  }
  
  .brandsIncluded {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 1.5vh;
  }
}