.modal-title {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0px;
  color: white;
}

.modal-img {
  padding: 40px;
}

.modal-heading {
  text-align: center;
}

.modal-button {
  background: rgba(217, 217, 217, 0.2);
  border: 1px solid transparent;
  outline: none;
  border-radius: 6px;  
  color: white;
  width: 20vw;
  height: 40px;
  font-size: 15px;
  margin-bottom: 15px;
}

.modal-btn-selected {
  background: white;
  border: 1px solid white;
  color: black;
}

.modal-submit-button {
  background: black;
  border: 1px solid white;
  border-radius: 6px;  
  color: white;
  width: 200px;
  height: 35px;
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.modal-submit-button:focus {
  background: #00b200;
  border: none;
}

@media screen and (max-width: 720px) {
  .modal-title {
    font-size: 24px;
    font-weight: 700;
  }

  .modal-button {
    width: 80vw;
  }
}