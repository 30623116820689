.caption {
	position: absolute;
	top: 0;
	left: 5px; /*  changed to match image_grid padding  */
	height: 100%;
	width: calc(100% - 5px); /*  changed to match image_grid padding  */
	padding: 0 10px;
	box-sizing: border-box;
	pointer-events: none;
}
.caption p {
	display: inline-block;
	padding: 10px;
	color: #fff;
	background: rgba(0, 0, 0, 0.5);
	font-family: "Myriad Pro regular";
	font-size: 15.31px;
}
.imageandtext {
	position: relative;
}
.image_grid {
	display: inline-block;
	padding-left: 5px;
}
.image_grid img {
	/*  added rule  */
	display: block;
}
.image_grid input {
	display: none;
}
.image_grid input:checked + .caption {
	background: rgba(0, 0, 0, 0.5);
}
.image_grid input:checked + .caption::after {
	content: "✔";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 70px;
	height: 70px;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 60px;
	line-height: 80px;
	text-align: center;
	/* border: 2px solid rgb(218, 203, 203); */
	border-radius: 100%;
}
