@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700;800;900&display=swap');

.pop-up-container {
  border-radius: 18px;
  background-image: url("assets/custom/MystryBox/PopUpImage.png");
  background-repeat: round;
  background-size: cover;
}

.pop-up-container-mobile {
  border-radius: 40px;
  background-image: url("assets/custom/MystryBox/PopUpImageMobile.png");
  background-repeat: round;
  background-size: cover;
}

.explore {
  color: white;
  background-color: black !important;
  width: 200px;
  height: 50px;
  border: 2px solid white;
  box-shadow: 4px white;
  border-radius: 10px;
}

.value-mobile {
  background-color: rgba(217, 217, 217, 0.8);
  border-radius: 10px;
  box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.25);
  max-width: 85vw;
  margin-top: 20px;
  padding: 5px 10px;
}