.see-more {
  background-color: #00b200;
  color: #F9F9F9;
  width: 240px;
  height: 40px;
  border: 1px solid #F9F9F9;
  font-family: "Exo 2", sans-serif;
  border-radius: 6px;
  margin-right: 12px;
}

.see-more-disabled {
  background-color: gray;
  color: #F9F9F9;
  width: 240px;
  height: 40px;
  border: none;
  font-family: "Exo 2", sans-serif;
  border-radius: 6px;
  margin-right: 12px;
}

.see-more-disabled:focus {
  outline: none;
}

.notify-me {
  background-color: #131313;
  font-family: "Exo 2", sans-serif;
  color: #F9F9F9;
  width: 180px;
  height: 40px;
  border: 1px solid #F9F9F9;
  border-radius: 6px;
  margin-right: 10px;
}

.see-more-xs {
  background-color: #00b200;
  color: #F9F9F9;
  width: 220px;
  height: 40px;
  border: 1px solid #F9F9F9;
  font-family: "Exo 2", sans-serif;
  border-radius: 6px;
}

.notify-me-xs {
  background-color: #131313;
  font-family: "Exo 2", sans-serif;
  color: #F9F9F9;
  width: 120px;
  height: 40px;
  border: 1px solid #F9F9F9;
  border-radius: 6px;
}

.videobox-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 70%; 
  overflow: hidden;
}

.videobox-container video {
  min-width: 100%; 
  min-height: 100%; 
  width: auto;
  height: auto;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%,-50%);
}