/* .slider{
    width: 110%;
    -webkit-appearance: none;
    height: 8px;
    border-radius: 5px;
    background: linear-gradient(to right,
        #FF0000 0%, #FDFF8F 50%, green 100% );
    outline: none;
    padding: 0;
    margin: 0;
}

.slider-color{
    color: white !important;
}
 */
/* .Clock {
    padding: 5px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
  } */

  .shoeInfoButton {
    padding: 10px 0px;
    cursor: pointer;
  }
  .placeBidButton {
    background-color: #00b200;
    border-radius: 10px;
    padding: 10px 0px;
  }
  .cancelButton {
    padding: 10px 0px;
    cursor: pointer;
    border: 2px solid #00b200;
    border-radius: 15px;
    background-color: black;
  }
  .login_signup_btn {
    background-color: white !important;
    color: black !important;
  }
  .shoeProductDetail {
    display: flex;
    justify-content: center;
  }
  .BidCol {
    color: black;
    cursor: pointer;
  }
