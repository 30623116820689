@font-face {
  font-family: "helveticaneuebold";
  src: local("helveticaneuebold"),
    url(../../../assets/fonts/helveticaneuebold.ttf) format("truetype");
}

.shoeDescriptionText {
  font-size: 1rem;
}

.shoeSizeButtonGroup {
  margin: 0px;
}
#newpost {
  display: none;
}

#newpost.visible {
  display: block;
}

details > summary {
  width: 15em;
  border: none;
  cursor: pointer;
  list-style: none;
}

details > p {
  border-radius: 0 0 10px 10px;
  padding: 2px 6px;
  margin: 0;
  box-shadow: 3px 3px 4px black;
}

.container {
  margin-top: 25px;
}
.alignRight {
  float: right;
}

.shoeBuyNowCol {
  display: flex;
  justify-content: flex-end;
}

.shoeBuyNowContainerCol {
  padding-left: 7.5%;
  padding-right: 7.5%;
}

.shoeRowBorder {
  border-bottom: 1px solid rgb(57, 62, 64);
  padding-top: 10px;
}

.shoeRowBorder:first-child {
  border-top: 1px solid rgb(57, 62, 64);
}

.shoeInfoButtons {
  padding: 10px 0px;
  cursor: pointer;
}

.checkoutCol {
  color: white;
  cursor: pointer;
}

/* .guestModal{
    overflow: hidden;
    border-radius: 15px;
    top: -5%;
} */

#guest-modal .cart-modal{
  background-color: black;
}

#confirm-modal .cart-modal{
  background-color: #1B1B1B;
}

.notifyModal .modal-content {
  background-color: #0f0a0a;
}

.confirmModal{
  overflow: hidden;
  border-radius: 15px;
  top: 10%;
}
.confirmModal .modal-content {
  background-color: #0f0a0a;
}

.shoeProductDetails {
  display: flex;
  justify-content: center;
}
.shoeSizeButton {
  margin: 0px;
  width: 170px;
  height: 50px;
}

.menuButton {
  border: 2px solid #32CD32;
  border-radius: 15px;
  background-color: #0f0a0a;
  width: 21.25rem;
  height: 50px; 
  position: relative;
  justify-content: center;
}

.buyButton {
  border-radius: 10px !important;
  margin: 10px;
  width: 170px;
  height: 50px;
}

.cartButtonNew {
  border: 2px solid #32CD32 !important;
  border-radius: 10px !important;
  background-color: #0f0a0a;
  margin: 10px;
  width: 170px;
  height: 50px;
}

.cartButton {
  margin: 0px;
  width: 170px;
  height: 50px;
}


.bidButton {
  margin: 0px;
  width: 170px;
  height: 50px;
}

.cartButton:hover {
  background-color: green;
}

.shoeImage {
  width: 100%;
}

.shoeSizeChart {
  position: relative;
  display: flex;
  justify-content: center;
}

#scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(128, 128, 128, 0.233);
}
#scrollbar::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
  height: 20px;
}
