.headerProduct{
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 100px;
}

@media only screen and (max-width: 600px) {
  .headerProduct{
    flex-direction: column-reverse;
    align-items: center;
  }

  .nameDesc {
    text-align: center;
  }

  .imageProduct {
    width: 80%;
  }

  .buyButtonProduct {
    padding: 2vw 3vw !important;
  }

  .notifyButtonProduct {
    padding: 2vw 3vw !important;
  }

  .mystryBoxProduct {
    bottom: 32px !important;
  }

  .stepOneHeader {
    font-size: 4em !important;
  }

  .stepTwoHeader {
    font-size: 4em !important;
  }

  .stepOneSteps {
    font-size: 0.6rem;
  }

  .stepTwoSteps {
    font-size: 0.6rem;
  }

  .stepOne {
    flex: 0.48 !important;
  }

  .stepTwo {
    flex: 0.48 !important;
  }

  .moreProductsHeader {
    font-size: 1.55rem !important;
  }

  .moreProductsCard {
    margin-bottom: 180px !important;
  }

  .yeezyProductPage {
    width: 50%;
  }

  .moreProductsShoeImage {
    animation: bounce 1s infinite ease-out;
  }
} 

.nameDesc{
  display: flex;
  flex-direction: column;
  color: #f9f9f9;
  flex: 0.35;
  justify-content: center;
}

.headerName {
  font-weight: 800;
  color: #f9f9f9;
  font-family: 'Exo 2', sans-serif;
  margin-bottom: clamp(50px, 3vw, 72px);
  font-size: clamp(2.5rem, 2vw, 2.5rem);
}

.headerDesc {
  margin-bottom: 4px;
  font-size: clamp(1rem, 2vw, 1.5rem);
}

.headerPrice{
  font-size: clamp(1rem, 2vw, 1.5rem);
}

.productPagePrice {
  margin-bottom: clamp(10px, 3vw, 72px);
  font-size: clamp(1.5rem, 2vw, 2.5rem);
  font-weight: 800;
  color: #00b200;
}

.ProductPageWrapper {
  color: #131313;
}

.buyButtonProduct {
  border: #f9f9f9;
  border-width: 0.5px;
  background-color: #00b200;
  padding: 0.5vw 2vw;
  border-radius: 6px;
  border-style: solid;
  color: #f9f9f9;
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(1rem, 1vw, 1.5rem);
  margin-right: 10px;
}

.notifyButtonProduct {
  border: #f9f9f9;
  border-width: 0.5px;
  margin-left: 10px;
  background-color: transparent;
  padding: 0.5vw 2vw;
  border-radius: 6px;
  border-style: solid;
  color: #f9f9f9;
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(1rem, 1vw, 1.5rem);
}

.imageProduct {
  display: flex;
  flex-direction: column;
  align-items: center;  
  flex: 0.25;
}

.imageProduct:hover .yeezyProductPage{
  animation: bounce 1s infinite ease-out;
}

.mystryBoxProduct{
  position: relative;
  bottom: 8%;
  z-index: -1;
}

.bodyProduct {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bodyDesc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
}

.blueAir {
  max-width: 100%;
}

.whiteAir {
  max-width: 100%;
}

.stepOne {
  border-bottom: 4px solid #f9f9f9;
  border-right: 2px solid #f9f9f9;
}

.stepTwo {
  border-left: 2px solid #f9f9f9;
  border-bottom: 4px solid #f9f9f9;
}

.bodyHeader {
  font-weight: 900;
  font-size: clamp(1.5rem, 2vw, 2.5rem);
}

.stepOne {
  display: flex;
  flex-direction: column;
  flex: 0.25;
}

.stepTwo {
  display: flex;
  flex-direction: column;
  flex: 0.25;
}

.stepOneSteps {
  display: flex;
}

.stepOneHeader {
  font-weight: 800;
  font-size: 6em;
}

.stepOneDesc {
  font-size: 1em;
  margin-top: 1em;
  margin-left: 1.25em;
  margin-right: 1.25em;
  font-weight: 300;
}

.stepTwoSteps {
  display: flex;
}

.stepTwoHeader {
  font-weight: 800;
  font-size: 6em;
  margin-left: 0.55em;
}

.stepTwoDesc {
  font-size: 1em;
  margin-top: 1em;
  margin-left: 1.25em;
  margin-right: 1.25em;
  font-weight: 300;
}

.moreProducts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.moreProductsHeader {
  font-weight: 800;
  font-size: clamp(1rem, 3rem, 5rem);
  margin-top: 25vh;
  margin-bottom: 30vh;
}

.moreProductsBody {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.moreProductsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  margin: 4vw;
  margin-bottom: 180px;
  flex-basis: 480px;
  height: 530px;
}

.shiftUp {
  position: relative;
  bottom: 155px;
}

.moreProductsCard:hover .moreProductsShoeImage{
  animation: bounce 1s infinite ease-out;
}

.moreProductsImageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.moreProductsImage {
  position: relative;
  width: 300px;
  bottom: 175px;
}

.moreProductsShoeImage {
  position: relative;
  width: 50%;
  bottom: 150px;
  z-index: 2;
}

.logoSection {
  display: flex;
  background-color: white;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
}

.nikeAir {
  padding: 10px;
}

.moreProductsName {
  font-size: 1.55em;
  font-weight: 800;
  margin-bottom: 8px;
}

.moreProductsGuarPrice {
  font-size: 1.15em;
  font-weight: 300;
}

.moreProductsPrice {
  font-size: 1.75em;
  color: #00b200;
  font-weight: 800;
}

.buttonsProductMore {
  margin-bottom: 40px;
}

.ProductPageMystryBox {
  width: 100%;
  margin-top: 90px;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}