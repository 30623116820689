.parent-div {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  height: 95vh !important;
  min-width: 100vw;
  border: 1px solid #000000;
}

.image-2 {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  height: 20vh;
  margin:auto;
}

.floating {  
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }    
}

.join-waitlist-btn {
  position: absolute;
  width: 250px;
  border-radius: 10px;
  box-shadow: 2px 4px rgba(91, 86, 86, 0.656);
  outline: none;
  color: #fff;
  line-height: 40px;
  margin-left: 40px;
  background-color: #000000;
  bottom:5%;
}

.join-waitlist-btn:hover {
  border-color: #000000;
  box-shadow: none;
}

.wiggle:hover{ 
  animation: wiggle 125ms infinite;
  animation-timing-function: linear;   
}

@keyframes wiggle {
  0% { transform: translate(5px, 0); }
  50% { transform: translate(-5px, 0); }
  100% { transform: translate(5px, 0); }
}