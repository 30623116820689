@import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");

/* @font-face {
	font-family: "edosz";
	src: local("edosz"), url(../../../assets/fonts/edosz.ttf) format("truetype");
} */

@keyframes inAnimation {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes outAnimation {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

.H1 {
	font-family: "Exo 2", sans-serif;
	padding-top: 2vh;
	align-items: center;
	text-align: center;
	font-size: 2rem;
	color: white;
	font-weight: bold;
}

.H3 {
	color: #181818;

	align-items: center;
	text-align: center;
	position: absolute;
	bottom: 0px;
	left: 220px;
	transform: translate(-10%, 50%);
}
.required {
	color: red;
}
/* body {
  background-color: #181818;
  color: rgb(71, 73, 75);
} */
.H4 {
	align-items: center;
	text-align: center;
	transform: translate(0%, -1400%);
	font-weight: bold;
	font-family: "Exo 2", sans-serif;
}

.number {
	font-family: "Exo 2";
	bottom: 10px;
	left: 10px;

	transform: translate(-250%, 0%);
	font-weight: bolder;
	font-size: medium;
	position: absolute;
	text-align: center;
}
.mainContainer {
	align-items: center;
	text-align: center;
}

.tinderCard {
	position: relative;
	align-items: center;
	height: 50vh;
	width: 750px;
	max-width: 85vw;
	padding: 20px;
	background-repeat: no-repeat;
	border-radius: 20px;
	background-size: cover;
	background-position: center;
	/*box-shadow: 8px 8px 2px 1px rgba(205, 133, 63, 0.6);*/
}
.cardContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 10vh;
}

.swipe {
	position: absolute;
}
.infoText {
	font-family: "Exo 2", sans-serif;
	font-weight: 500;
	position: fixed;
	bottom: 15vh;
	font-size: 0.8rem;
	left: 47%;
}

input[type="radio"] {
	margin-right: 5px;
}

.buttons {
	align-items: center;
	position: fixed;
	bottom: 10vh;
	width: 50%;
	display: flex;
	justify-content: space-evenly;
	padding-left: 50%;
}

.buttons .MuiIconButton-root {
	background-color: #1a1919;
}
.buttons_dislike {
	padding: 5px;
	color: #ffffff;
	background-color: #f74040
	/* color: #f74040; */
}

.fav {
  color: #ffffff;
  /* box-shadow: 20px 20px 30px #bebebe, -20px -20px 60px #ffffff; */
  background:#f74040;
}

.buttons_like {
	padding: 5px;
	color: #ffffff;
	background-color: #50fc59

	/* color: #50fc59; */
}
.buttons_neutral {
	padding: 5px;
	color: #ffffff;
	background-color: #cd5c7e

	/* color: #fffc57; */
}

.submitCard {
	/* background-color: #181818; */
	width: 50vmax;
	left: 25vw;
}

.instructionModal {
	margin-top: 3vh;
}

@media only screen and (max-width: 600px) {
	.H1 {
		padding-top: 5vh;
		font-size: 2rem;
	}
	.infoText {
		transform: translate(-10%, 100%);
	}

	.H4 {
		align-items: center;
		text-align: center;
		transform: translate(0%, -1200%);
		color: #181818;

		font-weight: bolder;
		font-size: 0.7rem;
	}
	.number {
		bottom: -15px;
		/* left: 400px; */
		/* left: 105vw; */
		left: 110%;
		font-size: 2vh;
		/* transform: translate(300%, 200%); */
	}
	.tinderCard {
		/* background-size: 300px 100px; */
		/* position: absolute; */
		max-width: 95vw;
		width: 400px;
		height: 30vh;
		/*box-shadow: 4px 4px 1px 1px rgba(205, 133, 63, 0.6);*/
	}
	.cardContainer {
		padding-top: 5vh;
	}
	.buttons {
		bottom: 5vh;
		padding-left: 50%;
		left: 0%;
	}
	.infoText {
		bottom: 12vh;
		left: 42%;
	}
	.submitCard {
		/* background-color: #181818; */
		width: 75vw;
		left: 12vw;
	}
	.instructionModal {
		margin-top: -4vh;
	}
}

@media only screen and (max-height: 450px) {
	.H1 {
		padding-top: 0vmax;
		bottom: 0vmax;
		font-size: 1.5rem;
		top: 20px;
		left: 33%;
		padding-bottom: 30px;
		position: absolute;
	}
	.infoText {
		transform: translate(-10%, 100%);
	}
	.H3 {
		bottom: 0%;
		position: absolute;
		text-align: center;
		transform: translate(-10%, 125%);

		left: 100px;
		font-size: 1rem;
	}
	.H4 {
		align-items: center;
		text-align: center;
		transform: translate(0%, -1200%);
		font-weight: bolder;
		font-size: 0.7rem;
	}
	.thankYouH4 {
		transform: translate(0%, 55%);
	}
	.thankYouButton {
		top: 10vh;
	}
	.number {
		bottom: -15px;
		/* left: 390px; */
		left: 105%;
		font-size: 3vh;
		/* transform: translate(100%, 125%); */
	}
	.tinderCard {
		width: 360px;
		height: 60vh;
		right: 2vh;
		bottom: 4vh;
		top: 2vh;
		/*box-shadow: 4px 4px 2px 1px rgba(205, 133, 63, 0.6);*/
	}
	.cardContainer {
		padding-top: 0vh;
	}
	.buttons {
		bottom: 0px;
		left: 5vh;
		padding-left: 20%;
		font-size: small;
	}
	.infoText {
		bottom: 11vh;
		left: 47%;
		font-size: 0.6rem;
	}

	.submitCard {
		/* background-color: #181818; */
		width: 60vw;
		left: 20vw;
		top: 15px;
	}

	.instructionModal {
		margin-top: -30vh;
	}
}

@media only screen and (max-height: 1280px) {
	.submitCard {
		width: 60vw;
		left: 20%;
		top: 15px;
	}
}
