#shop-col {
  margin: auto;
}

#shop-col .shoe {
  transform: translateY(15%);
  transition-timing-function: ease-in;
  transition-duration: 500ms;
  transition-delay: 250ms;
  z-index: 2;
}

#shop-col img + img {
  transform: translateY(0%);
  z-index: 1;
}

#shop-col:hover .shoe {
  transform: translateY(-2%);
  z-index: 3;
}

.logo {
  margin-bottom: 0px;
}

.shop-now-white {
  width: 150px;
  line-height: 30px;
  font-family: "Exo 2", sans-serif;
  margin-top: 15px;
  margin-bottom: 2vh;
  background-color: #E9E9E9;
  color: #000;
  border-radius: 5px;
}

.shop-now-white:hover {
  background-color: black;
  color: #E9E9E9;
  border-radius: 5px;
}

.shop-now-black {
  width: 150px;
  line-height: 30px;
  font-family: "Exo 2", sans-serif;
  margin-top: 15px;
  margin-bottom: 2vh;
  background-color: black;
  color: #E9E9E9;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
}

.shop-now-black:hover {
  background-color: #E9E9E9;
  color: black;
  border-radius: 5px;
}

.shoe-brand {
  color: black;
  margin-top: 15px;
  font-size: 22px;
  font-weight: 600;
  width: 50%;
  text-align: center;
  animation: cssAnimation 0s 500ms forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  to   { visibility: visible; }
}

.shoe-brand-white {
  color: #E9E9E9;
  margin-top: 15px;
  font-size: 22px;
  font-weight: 600;
  width: 50%;
  text-align: center;
  animation: cssAnimation 0s 500ms forwards;
  visibility: hidden;
}

.mystrybox {
  width: 75%;
}

.shoe-brand-xs {
  color: black;
  margin-top: 15px;
  font-size: 2vh;
  font-weight: 600;
  width: 85%;
  text-align: center;
}

.shoe-brand-white-xs {
  color: #E9E9E9;
  margin-top: 15px;
  font-size: 2vh;
  font-weight: 600;
  width: 85%;
  text-align: center;
}

#shop-col .shoe-mobile {
  transform: translateY(10%);
  animation: MoveUpDown 2s linear infinite;
  position: relative;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 2vh;
  }
}