.chef-image1{
    padding: 0;
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-height: 100vh;
    max-width: 100vw;
    filter: blur(25px)
}
.chef-image2{
    padding: 0;
    position: absolute;
    height: 100vh;
    align-items: center;
    left: 30vw;
    margin: 0 auto;
    max-height: 100vh;
    max-width: 50vw;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.chef-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.App {
    font-family: sans-serif;
    text-align: center;
    padding: 1rem;
}

.gallery {
    width: 100%;
}

.imgContainer {
    width: calc(100% / 2 - 1rem);
    margin: 0.5rem;
}

@media (min-width: 700px) {
    .imgContainer {
        width: calc(100% / 3 - 1rem);
        margin: 0.5rem;
    }
}

@media (min-width: 1200px) {
    .imgContainer {
        width: calc(100% / 4 - 1rem);
        margin: 0.5rem;
    }
}

.imgContainer img {
    border-radius: 5px;
}
