@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700;800;900&display=swap');

.explore-div-xs {
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding-left: 10vw;
}

.shop-heading {
  font-size: 80px;
  color: #F9F9F9;
  font-weight: 800;
  letter-spacing: 2px;
  word-spacing: 5px;
  line-height: 90px;
  font-family: "Exo 2", sans-serif;
}

.shop-heading-xs {
  font-size: 35px;
  color: #F9F9F9;
  font-weight: 800;
  line-height: 40px;
  font-family: "Exo 2", sans-serif;
}

.react-stacked-center-carousel-slide--1 {
  padding: 0vh !important;
}

.react-stacked-center-carousel-slide-0 {
  padding: 8vh !important;
  opacity: 0.7 !important;
  transform: translateX(30px) !important;
}

.slide-component {
  height: 875px;
}

.react-stacked-center-carousel-slide-1 {
  padding: 10vh !important;
  bottom: 8vh !important;
  opacity: 0.5 !important;
}

.explore-btn {
  width: 225px;
  line-height: 45px;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  margin-top: 50px;
  color: #F9F9F9;
  border-radius: 10px;
  background-color: #00B200;
}

.explore-btn-xs {
  width: 125px;
  line-height: 45px;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  margin-top: 25px;
  color: #F9F9F9;
  color: #000;
  border-radius: 10px;
}

.carousel-item {
  transition: opacity 1s ease-in-out;
}

.explore-shoe {
  position: relative;
  z-index: 99 !important;
  width: 75%;
}

.explore-box {
  transform: translateY(-38%) translateX(-20%);
  z-index: 2 !important;
  width: 50vw !important;
}