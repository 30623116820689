.faq-li .card {
  background-color: #fff !important;
  border: 1px solid white;
  border-radius: 15px;
}

.faq-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  color: #000000 !important;
}

.faq-btn {
  width: 180px;
  margin-bottom: 20px !important;
}

.faq-modal .modal-content {
  /* background-image: url("assets/custom/faq/faq-modal-bg.png");
  background-repeat: no-repeat;
  background-size: cover; */
  min-width: 50vw;
  min-height: 60vh;
  right: 25%;
  border: 1px solid #fff;
  border-radius: 25px;
}

.faq-card {
  min-height: 420px;
  max-width: 300px;
  margin-right: 20px !important;
  box-shadow: 6px 6px gray;
}

.faq-question {
  height: 15vh;
  width: 35vw;
  border: 1px solid transparent;
  background: #fff;
  overflow-x: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  position: absolute;
  top:0;
  text-align: center;
}

.faq-title {
  color: #000000;
  font-size: calc(0.8em + 1vw);
  padding: 15px 30px 15px 30px;
  font-weight: 600;
  line-height: 40px;
}

.faq-modal .modal-header .close {
  z-index: 1;
  color: #000000;
  margin-right: 0.2em;
  top: 35px !important;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.faq-content {
  padding: 25px;
  margin-top: 5vh;
}

.faq-answer {
  margin-top: 25px;
  width: 45vw;
  border: 1px solid transparent;
  height: 45vh;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 25px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#scroller {
  list-style: none;
  padding: 0;
  margin: 20px;
  overflow: auto;
  overflow-x: scroll;
  white-space: nowrap;
}

#scroller li {
  color: #fff;
  display: inline-block;
  font-size: 80px;
  font-weight: bold;
  height: 480px;
  line-height: 200px;
  text-align: center;
  white-space:initial;
}

#scroller::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

#scroller::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 25px !important; 
}

#scroller::-webkit-scrollbar-track-piece:end {
  background: gray !important;
  border-radius: 25px !important; 
  margin-right: 25vw; 
}

#scroller::-webkit-scrollbar-track-piece:start {
  background: gray !important;
  margin-left: 25vw;
  border-radius: 25px !important; 
}

@media screen and (max-width : 760px)
{
  .faq-title {
    padding: 0px;
    margin-top: 20px;
    font-weight: 600;
    line-height: 25px;
  }
  
  .faq-modal .modal-content{
    min-width: 80vw;
    min-height: 65vh;
    right: 0%;
  }

  .faq-question {
    height: 12vh;
    width: 60vw;
  }

  .faq-answer {
    margin-top: 25px;
    width: 85vw;
    height: 50vh;
    background: #fff;
  }

  .faq-modal .modal-header .close {
    padding: 5px;
  }
}