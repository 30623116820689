.video-meta {
  width: 100%;
  min-height: 100vh !important;
  position: relative;
}

.stage {
  position: absolute;
  bottom: 0;
  right: 0;
  color: black;
}

.icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  cursor: pointer;
  color: #FFF;
  line-height: 40px;
}

.icon:after {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  transition: transform 0.2s, opacity 0.2s;
}

.icon:hover:after {
  transform: scale(2.45);
  opacity: 0.8;
}

.pulsing:before {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  box-shadow: inset 0 0 0 3px #FFF;
  transition: transform 0.2s, opacity 0.2s;
  animation: pulsing 2.7s infinite;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}