.headerMystryProductContainer {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}

.mystryBoxProductSection {
  display: flex;
  border: 2px solid #d9d9d9;
  border-radius: 20px;
  width: 90%;
  justify-content: space-around;
  padding: 4vw 0 4vw 0;
  margin-bottom: 200px;
}

.headerMystry {
  margin-bottom: 40px;
  font-size: clamp(3rem, 1vw, 5rem);
}

.descMystry {
  margin-bottom: 2px;
}

.mystryProductLeft {
  flex: 0.5;
  font-size: clamp(0.75rem, 1vw, 5rem);
}

.mystryProductRight {
  display: flex;
  flex: 0.3;
}

.mystryProductRightImage {
  object-fit: contain;
}

.mystryProductPrice {
  font-size: clamp(2.25rem, 1vw, 5rem);
}

.mystryCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media screen and (max-width: 600px) {
  .mystryBoxProductSection {
    flex-direction: column-reverse;
    padding: 4vw 4vw 4vw 4vw;
    align-items: center;
  }

  .mystryProductRight {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .mystryProductLeft {
    margin-bottom: 20px;
  }

  .mystryProductRightImage {
    width: 60%;
  }

  .mystryProductRight {
    justify-content: center;
  }

  .headerMystry {
    margin-bottom: 20px;
  }

  .mystryProductPrice {
    margin-bottom: 16px;
  }

  .decMg {
    margin-bottom: 12px;
  }
}