.showPasswordLogin {
  font-size: 0.875rem;
}

.signInWithGoogle {
  margin-top: 10px !important;
  margin-bottom: 20px !important;

  width: 200px !important;

  /*margin: auto;*/

  /* height: 100px !important; */
  height: 40px !important;
}
.signInWithGoogle > span {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
}
.signInWithGoogle > div > svg {
  height: 40px !important;
}
.signInWithGoogle > div {
  height: 38px !important;
}

.icons {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;

  color: white;
}
a:link {
  color: green;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  background-color: transparent;
  text-decoration: underline;
}

.link {
  color: #00b200;
}
