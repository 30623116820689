/* checkout img: https://unsplash.com/photos/S-bDcSqbVrk */
.checkoutImgEmptyCart {
  height: 500px !important;
  width: 100% !important;
  margin-top: 30px;
}

.checkoutImgActual {
  /* height: 400px; */
  max-height: 350px;
  width: 100%;
  margin-bottom: 50px;
}

.checkoutAddressCardStyle {
  background-color: black;
  padding: 10px;
}

@media only screen and (max-width: 576px) {
  .checkoutLoginCol {
    margin-bottom: 25px;
  }

  .checkoutImgActual {
    max-height: 250px;
    margin-bottom: 50px;
  }
}
