.carousel .control-next.control-arrow:before {
  color: white;
  content: '';
  border: solid white;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 14px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
  color: white;
  content: '';
  border: solid white;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 14px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.read-more-btn {
  border: #f9f9f9;
  border-width: 0.5px;
  background-color: black;
  padding: 0.5vw 2vw;
  border-radius: 6px;
  border-style: solid;
  color: #f9f9f9;
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(1rem, 1vw, 1.5rem);
  font-weight: 700;
  margin-top: 72px;
}

.read-more-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}


@media (max-width: 600px) {
  .carousel.carousel-slider .control-arrow {
    padding: 0px !important;
  }

  .carousel .control-next.control-arrow:before {
    padding: 8px !important;
    border-width: 0 4px 4px 0 !important;
  }

  .carousel .control-prev.control-arrow:before {
    padding: 8px !important;
    border-width: 0 4px 4px 0 !important;
  }

  .read-more-btn {
    margin-top: 48px;
    padding: 2vw 8vw !important;
  }
}