@import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");

* {
  font-family: "Exo 2", sans-serif;
  /* font-weight: bold; */
}

.ChefsShoeImage {
  width: 9vmax;
  margin: 2vmax;
}

.ChefShoeRow {
  /* white-space: nowrap; */
  align-items: center;
  border-bottom: 1px solid rgb(57, 62, 64);
  padding-top: 2vmax;
  padding-bottom: 2vmax;
  cursor: pointer;
}

@font-face {
  font-family: "helveticaneuebold";
  src: local("helveticaneuebold"),
    url(../../../assets/fonts/helveticaneuebold.ttf) format("truetype");
}

@font-face {
  font-family: "bebasneue";
  src: local("bebasneue"),
    url(../../../assets/fonts/bebasneue.ttf) format("truetype");
}
