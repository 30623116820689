.sub-text {
  margin-bottom: 20px;
  text-align: center;
  font-size: 22px;
}

.subs-content {
  background-color: black !important;
}

.subscription-div {
  padding-top: 40px;
  padding-bottom: 25px;
  width: 100%;
}

.subscription-div-mobile {
  padding: 40px;
  padding-bottom: 25px;
  width: 100%;
}

.sub-text-mobile {
  margin-bottom: 15px;
  text-align: center;
  font-size: 20px;
}

.subs-form input {
  display: block;
  width: 100%;
  font-family: "Exo 2", sans-serif;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #F9F9F9;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  font-size: 1.2rem;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

.subs-content .form-control {
  background: rgba(217, 217, 217, 0.2) !important;
  height: 55px !important;
  border: 1px solid !important;
  border-color: black !important;
  width: 100% !important;
  border-radius: 16px !important;
  outline: 0 !important;
  font-size: 1.2rem !important;
}

.react-tel-input {
  margin-top: 10px !important;
  color: #F9F9F9 !important;
  border-radius: 25px;
  border: 0px solid !important;
  border-color: black !important;
}

.flag-dropdown {
  background-color: black !important;
}

.selected-flag {
  padding-left: 10px !important;
  background: rgba(146, 133, 133, 0.447) !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  border: 0px solid !important;
  border-color: black !important;
}

.country-list{
  background-color: black !important;
}

.country-list .country.highlight {
  background-color: black !important;
}

.country-list .country:hover {
  background-color: #00b200 !important;
}

.subs-button {
  padding: 12px;
  width: 180px !important; 
  margin-top: 15px !important;
  font-weight: 600;
  background: #00b200 !important;
  border: 1px solid #F9F9F9 !important;
  border-radius: 6px !important;
  color: #F9F9F9;
  font-size: 14px !important;
  line-height: 16.8px;
  outline: none !important;
  text-transform: uppercase;
  cursor: pointer;
  transition:all .3s;
}

.subs-button:hover {
  background: #32CD32;
  color: #F9F9F9;
}

.create-button {
  padding: 12px;
  width: 650px !important; 
  font-family: "Exo 2", sans-serif;
  margin-top: 20px;
  font-weight: 500;
  background: #00b200 !important;
  border: 1px solid #F9F9F9 !important;
  border-radius: 15px !important;
  color: #F9F9F9;
  font-size: 20px !important;
  line-height: 24px;
  outline: none !important;
  cursor: pointer;
  transition:all .3s;
}

.create-button:hover {
  background: #00b200;
  color: #F9F9F9;
}

.create-button-xs {
  padding: 12px;
  width: 85vw !important; 
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  background: #00b200 !important;
  border: 1px solid #F9F9F9 !important;
  border-radius: 10px !important;
  color: #F9F9F9;
  font-size: 20px !important;
  line-height: 24px;
  outline: none !important;
  cursor: pointer;
  transition:all .3s;
}

.create-button-xs:hover {
  background: #00b200;
  color: #F9F9F9;
}

.subs-field {
  border-radius: 16px;
  border: 1px solid !important;
  padding: 14px;
  background: rgba(217, 217, 217, 0.2) !important;
  border-color: #000000 !important;
}

.subs-field-modal {
  border-radius: 16px;
  padding: 14px;
  border: 1px solid !important;
  background: rgba(217, 217, 217, 0.2) !important;
  border-color: #000000 !important;
}

.hidden {
  visibility: hidden;
}
.show {
  visibility: visible;
}

@keyframes mymove {
  0%   {left: 50%;}
  100%  {left: 120%;}
}

.subs-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subs-wrapper .subs-content {
  background: black;
  padding: 0px;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  border-radius: 10px;
  text-align: center;
}

.subs-wrapper .subs-content footer input {
  border: unset;
  background-color: #F9F9F9;
  padding: var(--padding-15);
  font-size: 13px;
  border-radius: var(--radius-50);
  width: 250px;
}

.subs-wrapper .subs-content footer button {
  background-color: var(--button-background);
  color: #F9F9F9;
  border: unset;
  width: 120px;
  border-radius: var(--radius-50);
  padding: var(--padding-15);
  margin-left: 10px;
  cursor: pointer;
}

.subs-wrapper .subs-content footer input:focus, .subs-wrapper .subs-content footer button:focus {
  outline: none;
}

@media screen and (max-width: 720px) {
  .subs-wrapper .subs-content {
    background: black;
    padding: 0px;
    margin: 0px;
    width: 100%;
    max-width: 80vw;
    min-width: 75vw;
    border-radius: 10px;
    text-align: center;
  }
  .subs-wrapper .subs-content footer input {
    width: 200px;
    margin-bottom: 10px;
  }
}

.subscribeModal .modal-content {
  background-color: black;
  border-radius: 18px;
  border: 2px solid #E9E9E9;
}

.subscribeModal .modal-content .container {
  border-radius: 18px;
  margin-top: 0px;
}

.close-subscribe .icon-simple-remove::before{
  color: white;
}

.modal.fade.show {
  backdrop-filter: blur(2px);
}