.navbar-toggler-icon{
  position: fixed;
  right: 20px;
}

.navbar-toggler-icon-user{
  position: fixed;
  right: 75px;
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0 0 2px black;
  padding: 0.3em 0.4em;
}

.nav-collapsebar{
  position: absolute;
  padding: 25px;
  top: 10px !important;
  right: 10px !important;
  float: right !important;
  left: auto !important;
  background-color: black !important;
  max-width: 50vw !important;
  min-width: 20vw;
}