.MarketplaceShoeContainer {
  height: 30vmax;
  border-bottom: 1px solid rgb(57, 62, 64);
  border-right: 1px solid rgb(57, 62, 64);
  
  /* reference: https://stackoverflow.com/a/3088012/10401847*/
  cursor: pointer;
}

.MarketplaceShoeContainer:first-child {
  border-left: 1px solid rgb(57, 62, 64);
}

.MarketplaceShoeContainerTop {
  border-top: 1px solid rgb(57, 62, 64);
}

.marketPlaceCardText {
  position: absolute;
  vertical-align: top;
  text-align: left;
  top: 0.5vmax;
}

.backSideShoImg {
  height: 60%;
  width: 60%;
}

.backSideShoImgFlip {
  height: 100%;
  width: 100%;
  padding: 10%;
}

.backSideText {
  font-size: 1rem;
}

@media only screen and (max-width: 1200px) {
  .MarketplaceShoeContainer {
    height: 40vmax;
  }

  .marketPlaceCardText {
    position: absolute;
    vertical-align: top;
    text-align: left;
    top: 2px;
  }

  .backSideText {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 900px) {
  .MarketplaceShoeContainer {
    height: 50vmax;
  }

  .marketPlaceCardText {
    position: absolute;
    vertical-align: top;
    text-align: left;
    top: 2px;
  }

  .backSideText {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 450px) {
  .MarketplaceShoeContainer {
    height: 40vmax;
  }

  .marketPlaceCardText {
    position: absolute;
    vertical-align: top;
    text-align: left;
    top: 2px;
  }

  .backSideText {
    font-size: 0.875rem;
  }
}

@media only screen and (max-height: 450px) {
  .marketPlaceCardText {
    position: absolute;
    vertical-align: top;
    text-align: left;
    top: 2px;
  }

  .backSideText {
    font-size: 0.875rem;
  }
}
@font-face {
  font-family: "helveticaneuebold";
  src: local("helveticaneuebold"), url(../../../assets/fonts/helveticaneuebold.ttf) format("truetype");
}
