.ht-video {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -9;
}

.ht-container { position:relative; }
.ht-container video {
    position:relative;
    z-index:0;
}
.ht-overlay {
  position: absolute;
  top: 35%;
  left: 25%;
  z-index: 1;
}
.ht-overlay-bt {
  position: absolute;
  top: 80%;
  z-index: 1;
  font-size: 34px;
  font-weight: 700;
  opacity: 30% !important;
}
.desc-overlay {
  position: absolute;
  top: 55%;
  left: 15%;
  font-size: 3.5vh;
  width: 70vw;
  text-align: center;
  z-index: 1;
}
.ht-title {
  position: absolute;
  top: 20%;
  left: 10%;
  z-index: 1;
}
.ht-tech {
  position: absolute;
  top: 25%;
  left: 10%;
  z-index: 1;
  font-size: 64px;
  font-weight: 600;
}
.ht-content {
  position: absolute;
  top: 18%;
  left: 5%;
  right: 5%;
  z-index: 1;
  font-size: 3.5vh;
  max-width: 100vw;
}
.ht-tech-title {
  position: absolute;
  top: 37%;
  left: 10%;
  z-index: 1;
  font-size: 28px;
  font-weight: 600;
}
.ht-description {
  position: absolute;
  width: 48vw;
  top: 50%;
  left: 10%;
  z-index: 1;
  font-size: 26px;
  font-size: 400;
  line-height: 39px;
  color: white;
}
.ht-button {
  position: absolute;
  width: 20vw;
  top: 72%;
  left: 10%;
  z-index: 1;
  font-size: 2vh;
  border: 1px solid black;
  border-radius: 50px;
  padding: 10px;
  background-color: white;
  color: black;
  font-weight: 700;
}
.ht-button:hover {
  border: 2px solid white;
  background-color: black;
  color: white;
  font-weight: 700;
}
.ht-next {
  position: absolute;
  top: 90%;
  left: 88%;
  z-index: 2;
  text-decoration: underline;
  cursor: pointer;
}
.ht-prev {
  position: absolute;
  top: 90%;
  right: 85%;
  z-index: 2;
  text-decoration: underline;
  cursor: pointer;
}
#topText {
  color: white;
  font-size: 14vh;
  align-self: center;
}

.cssanimation, .cssanimation span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span { display: inline-block }

.fadeInBottom { animation-name: fadeInBottom }
@keyframes fadeInBottom {
  from {
      opacity: 0;
      transform: translateY(100%);
  }
  to { opacity: 1 }
}

.slide-right {
  animation: 2s slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

@media screen and (max-width: 600px) {
  .ht-title {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 20%;
    z-index: 1;
  }
  .ht-overlay {
    position: absolute;
    top: 35%;
    left: 15%;
    z-index: 1;
  }
  #topText {
    color: white;
    font-size: 5vh;
    align-self: center;
  }
  .desc-overlay {
    position: absolute;
    top: 45%;
    left: 15%;
    font-size: 2.5vh;
  }
  .ht-next {
    position: absolute;
    top: 90%;
    left: 75%;
  }
  .ht-prev {
    position: absolute;
    top: 90%;
    right: 70%;
  }
  .ht-tech-mob {
    margin-bottom: 2vh;
    font-size: 5vh;
    font-weight: 700;
    text-align: center;
    max-width: 100vw;
  }
  .ht-tech-title-mob {
    margin-bottom: 3vh;
    font-size: 2.6vh;
    font-weight: 700;
    width: 90vw;
    text-align: center;
  }
  .ht-description-mob {
    font-size: 2vh;
    width: 80vw;
    text-align: justify;
  }
  .ht-button {
    position: absolute;
    width: 60vw;
    top: 55%;
    left: 20%;
  }
}