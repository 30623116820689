.BackSideShoImg {
  width: 80% !important;
}

.StartingAt {
  color: #39ff14;
}

@media only screen and (max-width: 450px) {
  .BackSideShoImg {
    width: 60% !important;
  }

  .BackSideShoeTitle {
    font-size: 0.875rem;
  }

  .StartingAt {
    font-size: 0.875rem;
  }
}

@media only screen and (max-height: 450px) {
  .BackSideShoImg {
    width: 75% !important;
  }

  .BackSideShoeTitle {
    font-size: 0.875rem;
  }

  .StartingAt {
    font-size: 0.875rem;
  }
}
.CarouselShoeContainer {
  height: 18vmax;
  /* reference: https://stackoverflow.com/a/3088012/10401847*/
  cursor: pointer;
  bottom:'0';

}

