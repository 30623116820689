.brand-btn {
  background-color: black;
  color: white;
  border: 1px solid #2b3553;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 5px;
  text-transform: uppercase;
  font-size: 13px;
}

.brand-btn:focus {
  outline: none;
}

.brand-btn-pressed {
  background-color: white;
  color: black;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 5px;
  text-transform: uppercase;
  font-size: 13px;
}

.size-btn {
  background-color: black;
  color: white;
  border: 1px solid #2b3553;
  padding: 5px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.info-tooltip .tooltip-inner {
  max-width: none;
  width: 30vw;
  margin-top: 10px;
  position: absolute;
  right: -10vw;
}

.info-textarea {
  border: 1px solid #2b3553 !important;
  border-radius: 5px !important;
  min-height:150px;
  max-height:250px;
  min-width:100%;
  max-width:100%;
}

@media screen and (max-width: 720px) {
  .info-tooltip .tooltip-inner {
    max-width: none;
    width: auto;
    margin-top: 10px;
    position: relative;
    right: 0;
  }
}