@import "react-responsive-carousel/lib/styles/carousel.min.css";

/* reference: https://code2care.org/pages/how-to-make-div-or-text-in-html-unselectable-using-css */
.shoeContainer {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  margin: 0px;
  padding: 0px;
}

.shoeCol {
  margin: 0px;
  padding: 0px;
}

.greyedOut {
  opacity: 0.4;
  filter: grayscale(100%) blur(3px);
  -webkit-filter: grayscale(100%) blur(3px);
}

.BackSideShoImg {
  width: 80% !important;
}

.StartingAt {
  color: #39ff14;
}

@media only screen and (max-width: 450px) {
  .BackSideShoImg {
    width: 60% !important;
  }

  .BackSideShoeTitle {
    font-size: x-small;
  }

  .StartingAt {
    font-size: x-small;
  }
}

@media only screen and (max-height: 450px) {
  .BackSideShoImg {
    width: 75% !important;
  }

  .BackSideShoeTitle {
    font-size: x-small;
  }

  .StartingAt {
    font-size: x-small;
  }
}
