.page {
  /* background-image: url("../../../assets/custom/error4.png"); */
  background-image: url("../../../assets/custom/error2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  position: relative;
  overflow: hidden;
}
